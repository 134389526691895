import { Key, ReactNode } from "react";
import { RadioGroup as HRadioGroup } from "@headlessui/react";
import { clsx } from "clsx";

export type RadioOptionsCirclesProps<T> = {
    key: Key;
    value: T;
    label: ReactNode;
    description?: string;
    disabled?: boolean;
};

const RadioOptionsCircles = <ValueType,>({
    option,
    className,
}: {
    option: RadioOptionsCirclesProps<ValueType>;
    children?: ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <HRadioGroup.Option
            key={option.key}
            value={option.value}
            disabled={option.disabled}
            className={clsx(
                "relative flex focus:outline-none",
                "ui-not-disabled:cursor-pointer",
                className,
            )}
        >
            {({ checked }) => (
                <>
                    <div
                        className={clsx(
                            "flex",
                            option.description
                                ? "items-start pt-1"
                                : "items-center",
                        )}
                    >
                        <div className={clsx(option.description ? "pt-1" : "")}>
                            {!option.disabled ? (
                                checked ? (
                                    <CheckIcon />
                                ) : (
                                    <UncheckIcon />
                                )
                            ) : checked ? (
                                <DisabledCheckIcon />
                            ) : (
                                <DisabledUncheckIcon />
                            )}
                        </div>
                        <div className="flex flex-col pl-3">
                            <HRadioGroup.Label
                                as="p"
                                className={clsx(
                                    "text-neutral-900",
                                    "ui-not-disabled:hover:text-primary-500",
                                    "ui-active:text-primary-500",
                                )}
                            >
                                {option.label}
                            </HRadioGroup.Label>
                            {option.description && (
                                <HRadioGroup.Description
                                    as="span"
                                    className="text-neutral-500"
                                >
                                    <span>{option.description}</span>
                                </HRadioGroup.Description>
                            )}
                        </div>
                    </div>
                </>
            )}
        </HRadioGroup.Option>
    );
};

const RadioGroupCircles = <ValueType,>({
    children,
    className,
    ...props
}: {
    children?: ReactNode;
    className?: string;
    defaultValue?: ValueType;
    value?: ValueType;
    disabled?: boolean;
    onChange?: (v: ValueType) => void;
    by?: (keyof ValueType & string) | ((a: ValueType, z: ValueType) => boolean);
}): JSX.Element => {
    return (
        <HRadioGroup {...props}>
            <div className={clsx("py-2", className)}>{children}</div>
        </HRadioGroup>
    );
};

export type RadioOptionsButtonsProps<T> = {
    key: Key;
    value: T;
    label: string;
    disabled?: boolean;
};

const RadioGroupButtons = <ValueType,>({
    options,
    className,
    ...props
}: {
    options: RadioOptionsButtonsProps<ValueType>[];
    className?: string;
    defaultValue?: ValueType;
    value?: ValueType;
    disabled?: boolean;
    onChange?: (v: ValueType) => void;
    by?: (keyof ValueType & string) | ((a: ValueType, z: ValueType) => boolean);
}): JSX.Element => {
    const style = {
        normal: clsx(
            "flex-[1]",
            "relative",
            "border",
            "p-2",
            "ml-[-1px]",
            "text-neutral-900",
            "border-neutral-300",
            "ui-not-disabled:cursor-pointer",
            "ui-not-disabled:hover:text-primary-500",
        ),
        checked: clsx(
            "ui-checked:z-10",
            "ui-checked:border-primary-300",
            "ui-checked:text-primary-500",
        ),
        active: clsx(
            "ui-active:z-10",
            "ui-active:border-primary-300",
            "ui-active:text-primary-500",
        ),
        disabledUncheck: clsx(
            "ui-disabled:bg-neutral-100",
            "ui-disabled:text-neutral-500",
            "ui-disabled:border-neutral-300",
        ),
        disabledCheck: clsx(
            "ui-disabled:bg-neutral-200",
            "ui-disabled:text-neutral-500",
            "ui-disabled:border-neutral-300",
        ),
    };
    return (
        <>
            <HRadioGroup {...props}>
                <div className={clsx("flex w-full", className)}>
                    {options.map((option, i, arr) => (
                        <HRadioGroup.Option
                            key={option.key}
                            value={option.value}
                            disabled={option.disabled}
                            className="flex outline-none"
                        >
                            {({ checked }) => {
                                const disabledStyle = checked
                                    ? style.disabledCheck
                                    : style.disabledUncheck;
                                return (
                                    <div
                                        className={clsx(
                                            i == 0 ? "rounded-l" : "",
                                            i == arr.length - 1
                                                ? "rounded-r"
                                                : "",
                                            disabledStyle,
                                            style.active,
                                            style.checked,
                                            style.normal,
                                        )}
                                    >
                                        {option.label}
                                    </div>
                                );
                            }}
                        </HRadioGroup.Option>
                    ))}
                </div>
            </HRadioGroup>
        </>
    );
};

const dimension = "h-[18px] w-[18px]";

function CheckIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 24 24" fill="none" className={className + dimension}>
            <circle
                cx="12"
                cy="12"
                r="11.25"
                fill="white"
                stroke="#4361EE"
                strokeWidth="1.5"
            />
            <circle cx="12" cy="12" r="6" fill="#4361EE" />
        </svg>
    );
}

function UncheckIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 24 24" fill="none" className={className + dimension}>
            <circle
                cx="12"
                cy="12"
                r="11.25"
                fill="white"
                stroke="#A3A3A3"
                strokeWidth="1.5"
            />
        </svg>
    );
}

function DisabledCheckIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 24 24" fill="none" className={className + dimension}>
            <circle
                cx="12"
                cy="12"
                r="11.25"
                fill="#EEF2FF"
                stroke="#C7D2FE"
                strokeWidth="1.5"
            />
            <circle cx="12" cy="12" r="6" fill="#C7D2FE" />
        </svg>
    );
}

function DisabledUncheckIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 24 24" fill="none" className={className + dimension}>
            <circle
                cx="12"
                cy="12"
                r="11.25"
                fill="#F5F5F5"
                stroke="#E5E5E5"
                strokeWidth="1.5"
            />
        </svg>
    );
}

RadioGroupCircles.displayName = "RadioGroupCircles";
RadioOptionsCircles.displayName = "RadioOptionsCircles";
RadioGroupButtons.displayName = "RadioGroupButtons";
export { RadioGroupCircles };
export { RadioOptionsCircles };
export { RadioGroupButtons };
