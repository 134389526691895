import { InboxSolid } from "./Icons";

export const Empty = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center px-7 py-3">
            <InboxSolid className="h-20 w-20 fill-neutral-200 stroke-neutral-300 stroke-[0.5px]" />
            <span className="text-main text-neutral-500">No data</span>
        </div>
    );
};
