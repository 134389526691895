import clsx from "clsx";
import { ReactNode } from "react";

export const Card = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <div
            className={clsx(
                "inline-flex flex-col rounded-lg border border-neutral-200",
                className,
            )}
        >
            <div className="flex-[1]">{children}</div>
        </div>
    );
};
