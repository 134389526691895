import { HTMLInputTypeAttribute, ReactNode, forwardRef } from "react";
import { clsx } from "clsx";

type InputProps = {
    id?: string;
    type?: Exclude<HTMLInputTypeAttribute, "checkbox">;
    className?: string;
    step?: number;
    min?: string | number;
    max?: string | number;
    defaultValue?: string | number;
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    prefix?: ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ prefix, ...props }, ref): JSX.Element => {
        const style = {
            normal: clsx(
                "border-neutral-300",
                "text-primary-900",
                "hover:border-primary-300",
                "active:border-primary-800",
                "active:shadow-none",
                "focus-within:border-primary-300",
                "focus-within:shadow-[0_0_0_4px]",
                "focus-within:shadow-primary-100",
            ),
            disabled: clsx("border-none", "bg-neutral-100", "text-neutral-500"),
        };
        return (
            <div
                tabIndex={1}
                className={clsx(
                    "flex",
                    "h-10",
                    "items-center",
                    "rounded-md",
                    "border",
                    "border-solid",
                    "text-sub",
                    props.disabled ? style.disabled : style.normal,
                    props.className,
                )}
            >
                {prefix && <div className="ml-2">{prefix}</div>}
                <input
                    ref={ref}
                    {...props}
                    className="h-full w-full rounded-md px-2 py-2 caret-primary-500 outline-none"
                />
            </div>
        );
    },
);

type InputCheckboxProps = {
    id?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxProps>(
    (props, ref): JSX.Element => {
        const style = {
            normal: clsx(
                "border-neutral-300",
                "hover:border-primary-300",
                "hover:outline-offset-1",
                "hover:outline-primary-300",
            ),
            disabled: clsx(
                "border-neutral-500",
                "bg-neutral-100",
                "text-neutral-500",
            ),
        };
        return (
            <div>
                <input
                    ref={ref}
                    {...props}
                    className={clsx(
                        "rounded-md",
                        "text-white",
                        "outline-none",
                        "focus:outline-offset-1",
                        "focus:outline-primary-300",
                        "checked:focus:border-primary-300",
                        "checked:focus:shadow-[0_0_0_4px]",
                        "checked:focus:shadow-primary-100",
                        "checked:focus:outline-none",
                        props.disabled ? style.disabled : style.normal,
                        props.className,
                    )}
                    type="checkbox"
                />
            </div>
        );
    },
);

Input.displayName = "Input";
InputCheckbox.displayName = "InputCheckbox";
export { Input };
export { InputCheckbox };
