import clsx from "clsx";

type Size = "sm" | "md" | "lg";

type SkeletonProps = {
    loading?: boolean;
    size?: Size;
};

export const Skeleton: React.FC<SkeletonProps> = ({
    loading = true,
    size = "md",
}) => {
    const sizeToHeight = {
        sm: "h-3",
        md: "h-4",
        lg: "h-5",
    };

    const skeletonClasses = clsx(
        "bg-gray-200",
        "rounded",
        "animate-pulse",
        sizeToHeight[size],
    );

    if (!loading) {
        return null;
    }

    const skeletonElements = Array(5)
        .fill(null)
        .map((_, i) => (
            <div
                key={i}
                className={clsx(
                    skeletonClasses,
                    "w-full",
                    "first:w-1/3",
                    "last:w-1/2",
                )}
            />
        ));

    return <div className="flex flex-col gap-2">{skeletonElements}</div>;
};
