import { Icon } from "./Icon";

export const ChevronLeftOutline = ({
    className,
    wrapperClassName,
}: {
    className?: string;
    wrapperClassName?: string;
}) => {
    return (
        <Icon className={wrapperClassName}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                />
            </svg>
        </Icon>
    );
};
