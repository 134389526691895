import { Icon } from "./Icon";

export const PlusOutline = ({
    className,
    wrapperClassName,
}: {
    className?: string;
    wrapperClassName?: string;
}) => {
    return (
        <Icon className={wrapperClassName}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M12 4.5V19.5M19.5 12L4.5 12"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Icon>
    );
};
