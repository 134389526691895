import { ReactNode } from "react";

export const DeprecationWarning = ({
    children,
}: {
    children?: ReactNode;
}): JSX.Element => {
    return (
        <div className="mb-4 rounded bg-warning-200 p-8 text-center shadow">
            <div>
                This page is being deprecated. If the functionality of this page
                is still required, please let Tech know about it.
            </div>
            {children}
        </div>
    );
};
