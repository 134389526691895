import { Switch as HSwitch } from "@headlessui/react";
import clsx from "clsx";

export const Switch = ({
    size = "md",
    disabled,
    ...props
}: {
    size?: "sm" | "md";
    disabled?: boolean;
    checked: boolean;
    onChange: (checked: boolean) => void;
}) => {
    let outside = "w-[39px] h-[20px]";
    let inside = "w-[18px] h-[18px]";
    let travel = "ui-checked:translate-x-[19px] ui-not-checked:translate-x-0";
    if (size == "sm") {
        outside = "w-[30px] h-[14px]";
        inside = "w-[12px] h-[12px]";
        travel = "ui-checked:translate-x-[16px] ui-not-checked:translate-x-0";
    }
    const disabledStyle = clsx(
        "ui-checked:bg-primary-200",
        "bg-neutral-200",
        "pointer-events-none",
    );
    const enableStyle = clsx(
        "ui-checked:bg-primary-500",
        "bg-neutral-400",
        "cursor-pointer",
    );
    return (
        <HSwitch
            {...props}
            className={clsx(
                disabled ? disabledStyle : enableStyle,
                outside,
                "relative",
                "inline-flex",
                "items-center",
                "justify-start",
                "rounded-full",
                "border-transparent",
                "transition-colors",
                "duration-200",
                "ease-in-out",
                "focus:outline-none",
                "focus-visible:ring-2",
                "focus-visible:ring-white",
                "focus-visible:ring-opacity-75",
            )}
        >
            <span
                className={clsx(
                    travel,
                    inside,
                    "ml-[1px]",
                    "pointer-events-none",
                    "inline-block",
                    "transform",
                    "rounded-full",
                    "bg-white",
                    "shadow-lg",
                    "transition",
                    "duration-200",
                    "ease-in-out",
                )}
            />
        </HSwitch>
    );
};
