import { Dialog as HDialog, Transition } from "@headlessui/react";
import { Fragment, MutableRefObject, ReactNode } from "react";

export const Dialog = ({
    children,
    open,
    width,
    ...props
}: {
    children?: ReactNode;
    open: boolean;
    width?: "sm" | "md" | "lg" | "xl" | "full";
    onClose: () => void;
    initialFocus?: MutableRefObject<HTMLElement | null>;
    unmount?: boolean;
}): JSX.Element => {
    // The following will have type errors with Typescript currently
    // https://github.com/tailwindlabs/headlessui/issues/2468
    // TODO: remove emotion css as it seems to cause typing errors (probably because it tries to add the `css` prop)
    let w = "max-w-sm";
    if (width == "md") w = "max-w-md";
    else if (width == "lg") w = "max-w-lg";
    else if (width == "xl") w = "max-w-xl";
    else if (width == "full") w = "max-w-full";
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <Transition show={open} as={Fragment}>
            <HDialog {...props}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed inset-0 z-20 bg-black/30"
                    aria-hidden="true"
                />
                <Transition.Child
                    className="fixed inset-0 z-20 overflow-y-auto"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex min-h-full items-center justify-center p-4">
                        <HDialog.Panel
                            className={`${w} mx-auto rounded bg-white p-4`}
                        >
                            {children}
                        </HDialog.Panel>
                    </div>
                </Transition.Child>
            </HDialog>
        </Transition>
    );
};
