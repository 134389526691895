import clsx from "clsx";
import { ReactNode } from "react";

export const Icon = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <div className={clsx("fill-transparent stroke-black", className)}>
            {children}
        </div>
    );
};
