import { ReactNode } from "react";

const loadingBlue = (
    <svg
        className="flex-no-shrink animate-spin fill-current"
        viewBox="0 0 32 32"
    >
        <path
            d="M16 1.5C18.2882 1.5 20.544 2.04156 22.5829 3.0804C24.6217 4.11925 26.3857 5.62588 27.7307 7.47711C29.0757 9.32835 29.9635 11.4716 30.3215 13.7317C30.6794 15.9918 30.4974 18.3045 29.7903 20.4807"
            stroke="url(#paint0_linear_2755_24710)"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2755_24710"
                x1="7"
                y1="2.3604e-06"
                x2="28"
                y2="24.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0043FF" stopOpacity="0" />
                <stop offset="0.994811" stopColor="#0043FF" />
            </linearGradient>
        </defs>
    </svg>
);
const loadingWhite = (
    <svg
        className="flex-no-shrink animate-spin fill-current"
        viewBox="0 0 32 32"
    >
        <path
            d="M16 1.5C18.2882 1.5 20.544 2.04156 22.5829 3.0804C24.6217 4.11925 26.3857 5.62588 27.7307 7.47711C29.0757 9.32835 29.9635 11.4716 30.3215 13.7317C30.6794 15.9918 30.4974 18.3045 29.7903 20.4807"
            stroke="url(#paint0_linear_2755_24714)"
            strokeOpacity="0.6"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2755_24714"
                x1="-6.99382e-07"
                y1="16"
                x2="32"
                y2="16"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.385417" stop-color="white" stop-opacity="0" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
);

export const Spin = ({
    indicator,
    size,
    tip,
    iconColor = "blue",
}: {
    indicator?: JSX.Element;
    size?: "sm" | "md" | "lg";
    tip?: ReactNode;
    iconColor?: "white" | "blue";
}): JSX.Element => {
    let iconSize = "w-4 h-4";
    if (size == "md") {
        iconSize = "w-8 h-8";
    } else if (size == "lg") {
        iconSize = "w-12 h-12";
    }
    const style = `
        inline-flex
        flex-col
        items-center
        gap-1
    `;
    const defaultIconCss = `${iconSize} text-transparent`;
    const defaultIcon = iconColor == "white" ? loadingWhite : loadingBlue;
    return (
        <div className={style}>
            <div className={defaultIconCss}>
                {indicator ? indicator : defaultIcon}
            </div>
            {tip}
        </div>
    );
};
