import Button from "./Button";
import { Dialog } from "./Dialog";
import { ReactNode, useCallback, useMemo, useState } from "react";

type ConfirmationDialogProps = {
    icon?: ReactNode;
    title?: ReactNode;
    content?: ReactNode;
    open: boolean;
    onOk: () => void | Promise<void>;
    onCancel: () => void | Promise<void>;
    okText?: ReactNode;
    okType?: "success" | "danger";
    cancelText?: ReactNode;
};

/**
 * ConfirmationDialog is meant to be used alongside {@link useConfirmation} hook.
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *     const [props, setProps] = useConfirmation();
 *     const onClick = () => {
 *         setProps({
 *             title: "Signing out",
 *             content: "Are you sure?",
 *             onOk: async () => await signout(),
 *             okText: "Signout",
 *         })
 *     }
 *
 *     return (
 *         <div>
 *             <button onClick={onClick}>Signout</button>
 *             <ConfirmationDialog {...props} />
 *         </div>
 *     );
 * }
 * ```
 */
export const ConfirmationDialog = ({
    icon,
    title,
    content,
    open,
    onOk,
    onCancel,
    okText = "Ok",
    cancelText = "Cancel",
    okType = "success",
}: ConfirmationDialogProps): JSX.Element => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <div className="flex items-baseline text-base">
                <div className="flex w-8 items-baseline justify-center text-lg">
                    {icon}
                </div>
                <div className="pr-4">{title}</div>
            </div>
            <div className="mt-4 pl-8 pr-4">{content}</div>
            <div className="mt-4 flex justify-end gap-2 px-4">
                <Button type="secondary" onClick={onCancel}>
                    {cancelText}
                </Button>
                <Button destructive={okType === "danger"} onClick={onOk}>
                    {okText}
                </Button>
            </div>
        </Dialog>
    );
};

type UseConfirmationOptions = Partial<Omit<ConfirmationDialogProps, "open">>;

/**
 * useConfirmation is meant to be used alongside {@link ConfirmationDialog}.
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   const [props, setProps] = useConfirmation();
 *   const onClick = () => {
 *     setProps({
 *       title: "Signing out",
 *       content: "Are you sure?",
 *       onOk: async () => await signout(),
 *       okText: "Signout",
 *     })
 *   }
 *
 *   return (
 *     <div>
 *       <button onClick={onClick}>Signout</button>
 *       <ConfirmationDialog {...props} />
 *     </div>
 *   );
 * }
 * ```
 */
export const useConfirmation = (): [
    ConfirmationDialogProps,
    (opts: UseConfirmationOptions) => void,
] => {
    const [open, setOpen] = useState(false);
    const [props, setProps] = useState<UseConfirmationOptions>({});
    const setOpts = useCallback(
        (values: UseConfirmationOptions) => {
            setProps(values);
            setOpen(true);
        },
        [setProps, setOpen],
    );
    const onOk = useCallback(async () => {
        await props.onOk?.();
        setOpen(false);
    }, [props, setOpen]);
    const onCancel = useCallback(async () => {
        await props.onCancel?.();
        setOpen(false);
    }, [props, setOpen]);

    return useMemo(
        () => [{ ...props, onOk, onCancel, open }, setOpts],
        [props, onOk, onCancel, open, setOpts],
    );
};
