export const typography = {
    heading: {
        h1: "text-[32px] font-bold leading-[1.4]",
        h2: "text-[24px] font-bold leading-[1.4]",
        h3: "text-[20px] font-bold leading-[1.4]",
        h4: "text-[18px] font-bold leading-[1.4]",
        h5: "text-[16px] font-bold leading-[1.4]",
        h6: "text-[14px] font-bold leading-[1.4]",
    },
    content: {
        main: "text-[16px] leading-[1.4]",
        mainBold: "font-bold text-[16px] leading-[1.4]",
        sub: "text-[14px] leading-[1.4]",
        subBold: "font-bold text-[14px] leading-[1.4]",
        tiny: "text-[12px] leading-[1.4]",
        tinyBold: "font-bold text-[12px] leading-[1.4]",
        micro: "text-[9px] leading-[1.4]",
        microBold: "font-bold text-[9px] leading-[1.4]",
    },
    paragraph: {
        default: "text-[16px] leading-[1.6]",
        bold: "font-bold text-[16px] leading-[1.6]",
    },
    link: "leading-[1.6] text-secondary-bright hover:underline",
};
