import clsx from "clsx";
import { Icon } from "./Icon";

export const Google = ({
    className,
    wrapperClassName,
}: {
    className?: string;
    wrapperClassName?: string;
}) => {
    return (
        <Icon className={wrapperClassName}>
            <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={clsx("stroke-none", className)}
            >
                <g clip-path="url(#a)">
                    <path
                        d="m23.8 12.3-.2-2.5H12.2v4.7h6.5a5.6 5.6 0 0 1-2.4 3.6v3h3.9c2.3-2 3.6-5.2 3.6-8.8Z"
                        fill="#4285F4"
                    />
                    <path
                        d="M12.2 24c3.3 0 6-1 8-2.9l-3.9-3c-1 .7-2.4 1.2-4 1.2a7.2 7.2 0 0 1-6.8-5h-4v3c2 4.1 6.2 6.7 10.7 6.7Z"
                        fill="#34A853"
                    />
                    <path
                        d="M5.5 14.3a7.2 7.2 0 0 1 0-4.6v-3h-4a12 12 0 0 0 0 10.7l4-3.1Z"
                        fill="#FBBC04"
                    />
                    <path
                        d="M12.2 4.7c1.8 0 3.4.7 4.6 1.8l3.5-3.4A12 12 0 0 0 1.5 6.6l4 3.1c1-2.8 3.6-5 6.7-5Z"
                        fill="#EA4335"
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h24v24H0z" />
                    </clipPath>
                </defs>
            </svg>
        </Icon>
    );
};
