import clsx from "clsx";
import { ReactNode } from "react";
import { Spin } from "./Spin";

type ButtonHTMLType = "button" | "submit" | "reset";
export type ButtonType =
    | "primary"
    | "secondary"
    | "tertiary"
    | "outlined"
    | "text";
type ButtonProps = {
    children?: ReactNode;
    type?: ButtonType;
    htmlType?: ButtonHTMLType;
    icon?: ReactNode;
    size?: "small" | "medium" | "large";
    block?: boolean;
    destructive?: boolean;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
};
const Button = ({
    children,
    type = "primary",
    htmlType = "button",
    icon,
    size = "medium",
    block = false,
    destructive = false,
    disabled = false,
    loading = false,
    onClick,
    ...props
}: ButtonProps): JSX.Element => {
    if (loading) {
        disabled = true;
        icon = <Spin size="sm" />;
    }

    const typeClasses = {
        primary: {
            default: [
                "border-transparent",
                !disabled && [
                    "bg-primary-600 text-white",
                    "hover:bg-primary-500 hover:shadow-md",
                    "focus:bg-primary-500 focus:outline-primary-700",
                    "active:bg-primary-800",
                ],
                "disabled:bg-neutral-300 disabled:text-neutral-400 ",
            ],
            destructive: [
                "border-transparent text-white ",
                !disabled && [
                    "bg-destructive",
                    "hover:bg-destructive-600",
                    "active:bg-destructive-800",
                ],
                "disabled:bg-destructive-300",
            ],
        },
        secondary: {
            default: [
                "border-transparent",
                !disabled && [
                    "bg-primary-50 text-primary-600",
                    "hover:bg-primary-100 hover:shadow-md",
                    "focus:border-primary-500 focus:bg-primary-100",
                    "active:bg-primary-100",
                ],
                "disabled:bg-primary-50 disabled:text-primary-300",
            ],
            destructive: [
                "border-transparent bg-destructive-50",
                !disabled && [
                    "text-destructive-500",
                    "hover:bg-destructive-100 hover:text-destructive-600",
                    "active:bg-destructive-50",
                ],
                "disabled:text-destructive-300",
            ],
        },
        tertiary: {
            default: [
                "border-neutral-200 bg-white",
                !disabled && [
                    "text-neutral-700",
                    "hover:border-neutral-300 hover:bg-neutral-50 hover:shadow-md",
                    "focus:border-neutral-400 focus:bg-neutral-100",
                    "active:border-transparent active:bg-primary-100",
                ],
                "disabled:text-neutral-300",
            ],
            destructive: [
                "border-destructive-200 bg-white",
                !disabled && [
                    "text-destructive-500",
                    "hover:bg-destructive-100 hover:text-destructive-500",
                    "hover:text-destructive-400 active:bg-destructive-50",
                ],
                "disabled:text-destructive-300",
            ],
        },
        outlined: {
            default: [
                "border-primary-500 bg-white text-primary-500",
                !disabled && [
                    "hover:bg-primary-50 hover:shadow-md",
                    "focus:border-primary-800 focus:bg-primary-50",
                    "active:bg-primary-100",
                ],
                "disabled:border-primary-300 disabled:text-primary-200",
            ],
            destructive: [
                "border-destructive-500 bg-white text-destructive-500",
                !disabled && [
                    "hover:border-destructive-400 ",
                    "active:border-destructive-400 active:text-destructive-400",
                ],
                "disabled:border-destructive-300 disabled:text-destructive-200",
            ],
        },
        text: {
            default: [
                "border-transparent",
                !disabled && [
                    "text-primary-500",
                    "hover:bg-primary-50",
                    "focus:border-primary-300 focus:bg-primary-50",
                    "active:bg-primary-100",
                ],
                "disabled:text-primary-300",
            ],
            destructive: [
                "border-transparent",
                !disabled && [
                    "text-destructive",
                    "hover:text-destructive-600",
                    "active:text-destructive",
                ],
                "disabled:text-destructive-300",
            ],
        },
    };
    const sizeClasses = {
        small: "h-7 px-3 py-1.5 text-micro",
        medium: "h-10 px-4 py-2.5 text-tiny",
        large: "h-11 px-5 py-3 text-sub",
    };
    const classes = clsx(
        "flex cursor-pointer items-center gap-2 rounded border font-medium transition-all focus:outline-0 active:hover:shadow-none disabled:cursor-not-allowed",
        destructive ? typeClasses[type].destructive : typeClasses[type].default,
        sizeClasses[size],
        block && "w-full justify-center",
    );
    return (
        <button
            type={htmlType}
            className={classes}
            disabled={disabled}
            onClick={() => onClick?.()}
            {...props}
        >
            {icon}
            {children}
        </button>
    );
};

export default Button;
