import { ReactNode, forwardRef, useImperativeHandle, useRef } from "react";
import { Popover as PopoverPrimitive, Transition } from "@headlessui/react";
import clsx from "clsx";
import { typography } from "utils/typography";
import Button, { ButtonType } from "./Button";

type Size = "sm" | "md" | "lg";
type Placement =
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
type PopoverProps = {
    buttonLabel: string;
    buttonType?: ButtonType;
    disabled?: boolean;
    title?: string;
    size?: Size;
    content: ReactNode;
    placement?: Placement;
    destructive?: boolean;
    block?: boolean;
};

export const Popover = forwardRef(
    (
        {
            buttonLabel,
            buttonType = "primary",
            title,
            size = "md",
            disabled = false,
            placement = "bottom-left",
            content,
            destructive = false,
            block = false,
        }: PopoverProps,
        ref: React.Ref<{ close: () => void }>,
    ): JSX.Element => {
        const buttonRef = useRef<HTMLButtonElement>(null);
        const sizeClasses = {
            sm: "min-w-[150px] min-h-[200px] p-2 text-tiny",
            md: "min-w-[220px] min-h-[260px] p-3 text-sub",
            lg: "min-w-[350px] min-h-[450px] p-4 text-sub",
        };
        const placementClasses = {
            "top-left": "inset-y-0 left-0 mt-2",
            "top-center": "inset-y-0 left-1/2 transform -translate-x-1/2 mt-2",
            "top-right": "inset-y-0 right-0 mt-2",
            "bottom-left": "inset-y-0 left-0 top-2 mb-2",
            "bottom-center":
                "inset-y-0 left-1/2 transform -translate-x-1/2 mb-2",
            "bottom-right": "inset-y-0 right-0 mb-2",
        };
        useImperativeHandle(ref, () => ({
            close: () => {
                buttonRef.current?.click();
            },
        }));

        return (
            <PopoverPrimitive title={title}>
                <div className="relative">
                    <PopoverPrimitive.Button ref={buttonRef} as="div">
                        <Button
                            type={buttonType}
                            destructive={destructive}
                            block={block}
                            disabled={disabled}
                        >
                            {buttonLabel}
                        </Button>
                    </PopoverPrimitive.Button>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Transition
                        className="absolute z-10 w-full"
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <PopoverPrimitive.Panel
                            static
                            className={clsx(
                                "absolute w-full overflow-auto rounded-xl bg-white shadow-xxlarge focus:outline-none",
                                sizeClasses[size],
                                placementClasses[placement],
                            )}
                        >
                            <div
                                className={clsx(
                                    "pb-2",
                                    typography.paragraph.bold,
                                )}
                            >
                                {title}
                            </div>
                            {content}
                        </PopoverPrimitive.Panel>
                    </Transition>
                </div>
            </PopoverPrimitive>
        );
    },
);

Popover.displayName = "Popover";

export default Popover;
