import { Fragment, ReactNode } from "react";
import clsx from "clsx";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { typography } from "utils/typography";
import { ChevronLeftOutline, ChevronRightOutline } from "components/Icons";
import { Skeleton } from "./Skeleton";
import { Select } from "./Select";

type Size = "sm" | "md" | "lg";
type ListProps<T> = {
    loading?: boolean;
    header?: ReactNode;
    dataSource?: T[];
    size?: Size;
    renderItem?: (item: T, index: number, array: T[]) => ReactNode;
    footer?: ReactNode;
    pagination?: ListPaginationProps;
};
type ListItemsProps<T> = {
    loading?: boolean;
    dataSource?: T[];
    size?: Size;
    renderItem?: (item: T, index: number, array: T[]) => ReactNode;
};
type ListPaginationProps = {
    hasData?: boolean;
    size?: Size;
    pageSize?: number;
    onPageSizeChange?: (value: number) => void;
} & Partial<ReactPaginateProps>;
const listSize = {
    sm: "py-1 px-2 text-tiny",
    md: "py-2 px-3 text-sub",
    lg: "py-3 text-main",
};

const ListHeader = ({
    header,
    size = "md",
}: {
    header: ReactNode;
    size?: "sm" | "md" | "lg";
}) => {
    if (!header) {
        return null;
    }
    return <div className={listSize[size]}>{header}</div>;
};
const ListFooter = ({
    footer,
    size = "md",
}: {
    footer: ReactNode;
    size?: "sm" | "md" | "lg";
}) => {
    if (!footer) {
        return null;
    }
    return <div className={listSize[size]}>{footer}</div>;
};

const ListItems = <ValueType,>({
    loading = false,
    dataSource = [],
    renderItem,
    size = "md",
}: ListItemsProps<ValueType>) => {
    if (!dataSource.length) {
        return (
            <div
                className={clsx(
                    listSize[size],
                    "flex items-center justify-center py-4 text-neutral-400",
                )}
            >
                No data
            </div>
        );
    }
    if (loading) {
        return <Skeleton />;
    }
    return (
        <ul className="flex flex-col divide-y divide-solid border-y">
            {dataSource.map((item, index, array) =>
                renderItem ? (
                    <li
                        className={clsx(
                            typography.paragraph.default,
                            listSize[size],
                            "list-none leading-loose",
                        )}
                        key={index}
                    >
                        {renderItem(item, index, array)}
                    </li>
                ) : null,
            )}
        </ul>
    );
};

const ListPagination = ({
    hasData,
    size = "md",
    pageSize,
    onPageSizeChange,
    ...props
}: ListPaginationProps) => {
    const { pageCount } = props;
    const hasNoData = !hasData;
    const hasNoProps = !Object.keys(props).length;
    const pageCountUndefined = pageCount === undefined;

    const itemOptions = [
        { value: "10", name: "10 / page" },
        { value: "20", name: "20 / page" },
        { value: "50", name: "50 / page" },
        { value: "100", name: "100 / page" },
    ];

    const handlePageSizeChange = (value: string) => {
        if (!onPageSizeChange) {
            return;
        }
        const newValue = parseInt(value);
        onPageSizeChange(newValue);
    };

    return hasNoData || hasNoProps || pageCountUndefined ? null : (
        <div className="flex justify-end gap-2">
            <ReactPaginate
                pageCount={pageCount}
                disableInitialCallback
                previousLabel={<ChevronLeftOutline />}
                nextLabel={<ChevronRightOutline />}
                activeLinkClassName="border border-primary font-bold text-primary"
                containerClassName="flex gap-2 items-center justify-end"
                pageLinkClassName={clsx(
                    listSize[size],
                    "rounded text-neutral-900 no-underline duration-75 hover:bg-neutral-200",
                )}
                {...props}
            />
            {onPageSizeChange && pageSize && (
                <Select
                    value={pageSize.toString()}
                    className="max-w-[120px]"
                    options={itemOptions}
                    onChange={handlePageSizeChange}
                />
            )}
        </div>
    );
};

export const List = <ValueType,>({
    loading = false,
    header,
    size = "md",
    footer,
    renderItem,
    dataSource = [],
    pagination,
}: ListProps<ValueType>): JSX.Element => {
    return (
        <Fragment>
            <ListHeader header={header} size={size} />
            <ListItems
                dataSource={dataSource}
                loading={loading}
                size={size}
                renderItem={renderItem}
            />
            <ListFooter footer={footer} size={size} />
            <ListPagination hasData={!!dataSource.length} {...pagination} />
        </Fragment>
    );
};

export default List;
