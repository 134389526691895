import { Icon } from "./Icon";

export const ArrowDownTrayOutline = ({
    className,
    wrapperClassName,
}: {
    className?: string;
    wrapperClassName?: string;
}) => {
    return (
        <Icon className={wrapperClassName}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M3 16.5V18.75C3 19.9926 4.00736 21 5.25 21H18.75C19.9926 21 21 19.9926 21 18.75V16.5M16.5 12L12 16.5M12 16.5L7.5 12M12 16.5V3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Icon>
    );
};
