import { ReactNode } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { ChevronDownOutline } from "components/Icons";

export type Option<VT extends string = string> = {
    value: VT;
    name: string;
    disabled?: boolean;
    children?: ReactNode;
};

export type SelectProps<VT extends string = string> =
    | SelectPropsMultiple<VT>
    | SelectPropsSingle<VT>;

type SelectPropsMultiple<VT extends string = string> = {
    className?: string;
    value: VT[] | undefined;
    defaultValue?: VT[];
    placeholder?: string;
    options: Option<VT>[];
    disabled?: boolean;
    multiple: true;
    onChange: (value: VT[]) => void;
};

type SelectPropsSingle<VT extends string = string> = {
    className?: string;
    value: VT | undefined;
    defaultValue?: VT | undefined;
    placeholder?: string;
    options: Option<VT>[];
    disabled?: boolean;
    multiple?: false | undefined;
    onChange: (value: VT) => void;
};

export const Select = <VT extends string = string>({
    value = undefined,
    defaultValue,
    placeholder = "Select an option",
    options,
    disabled,
    multiple,
    className,
    onChange,
    ...props
}: SelectProps<VT>): JSX.Element => (
    <Listbox
        value={value ?? (multiple ? [] : null)}
        defaultValue={defaultValue ?? (multiple ? [] : null)}
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        as="div"
        className={clsx("relative w-full min-w-[100px]", className)}
        {...props}
    >
        <Listbox.Button
            className={clsx(
                "flex min-h-[40px] w-full items-center rounded-md border border-gray-300 bg-white px-2 py-1 shadow-sm focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-200",
                !value && "text-gray-500",
                multiple && !value?.length && "text-gray-500",
            )}
        >
            {!multiple ? (
                <div className="w-full truncate text-left">
                    {value !== undefined
                        ? options.find(
                              ({ value: optionValue }) => optionValue === value,
                          )?.name || value
                        : placeholder}
                </div>
            ) : (
                <div className="grow text-left">
                    {Array.isArray(value) && value.join(", ")}
                </div>
            )}
            <ChevronDownOutline className="h-5 w-5 shrink-0 stroke-gray-300" />
        </Listbox.Button>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Transition
            className="absolute z-10 w-full"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
        >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto bg-white text-base leading-6 shadow-xl focus:outline-none sm:text-sm sm:leading-5">
                {options.map(({ value, name, disabled }) => (
                    <Listbox.Option
                        value={value}
                        key={value}
                        disabled={disabled}
                        className={({ selected, active, disabled }) =>
                            clsx(
                                "relative cursor-pointer select-none truncate py-1 pl-2 pr-9 text-[14px]",
                                {
                                    "bg-blue-200 font-bold": selected,
                                    "bg-gray-100": active,
                                    "bg-gray-100 text-gray-200": disabled,
                                },
                            )
                        }
                    >
                        {name ? name : value}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Transition>
    </Listbox>
);
