import { Tab } from "@headlessui/react";
import { ReactNode } from "react";
import clsx from "clsx";

type TabProps = {
    [key: string]: { items: ReactNode; disabled?: boolean };
};

export const Tabs = ({
    tabs,
    ...props
}: {
    tabs: TabProps;
    defaultIndex?: number;
    selectedIndex?: number;
    onChange?: (idx: number) => void;
}): JSX.Element => {
    return (
        <div className="w-full px-2 sm:px-0">
            <Tab.Group {...props}>
                <Tab.List className="flex max-w-max space-x-1 rounded-xl bg-primary-100 p-1">
                    {Object.entries(tabs).map(([key, tab]) => (
                        <Tab
                            key={key}
                            disabled={tab.disabled}
                            className={() =>
                                clsx(
                                    "w-full rounded-lg px-6 py-2.5 leading-5 text-primary",
                                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2",
                                    "hover:bg-primary-50 hover:text-primary-600 hover:shadow-sm",
                                    "ui-selected:bg-white ui-selected:text-primary-900 ui-selected:shadow",
                                    "disabled:bg-inherit disabled:text-neutral-600 disabled:shadow-none",
                                )
                            }
                        >
                            {key}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {Object.entries(tabs).map(([key, value]) => (
                        <Tab.Panel
                            key={key}
                            className={clsx("rounded-xl bg-white py-3")}
                        >
                            {value.items}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
